import { MainNav as Navbar } from "./layouts";
import { Footer } from "./layouts";
import { Paths } from "./pages";
import { AuthContextProvider } from "./features/auth";
import ReactGA from "react-ga4";
import "./assets/app.scss";

export const App = () => {
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_GA_TRACKING_ID;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    ReactGA.initialize(TRACKING_ID, { debug: true });
    console.log("This is a development build");
  } else {
    ReactGA.initialize(TRACKING_ID, { standardImplementation: true });
  }
  return (
    <AuthContextProvider>
      <Navbar />
      <Paths />
      <Footer />
    </AuthContextProvider>
  );
};
