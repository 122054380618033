import { OptionCount } from "./optioncount";
import { OptionPresent } from "./optionpresent";
import { WhiteCount } from "./whitecount";
import { PassCount } from "./passcount";

export const CountButton = ({
  counted,
  diffData,
  setDiffData,
  disableMax,
  print,
  setPrint,
}) => {
  switch (counted.rank) {
    case "rate":
      return <OptionCount print={print} setPrint={setPrint} />;
    case "present":
      return <OptionPresent print={print} setPrint={setPrint} />;
    case "pass":
      return <PassCount counted={counted} print={print} setPrint={setPrint} />;
    case "count":
      return (
        <WhiteCount
          counted={counted}
          diffData={diffData}
          setDiffData={setDiffData}
          disableMax={disableMax}
          print={print}
          setPrint={setPrint}
        />
      );
    default:
      console.log("Counttype is:", counted.rank);
      return <></>;
  }
};
