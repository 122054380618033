import { SignInForm } from "./signinform";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

export const SignInPage = () => (
  <Container fluid="sm" className="m-3 p-3">
    <Row className="justify-content-center">
      <Col md={3} id="majorframe">
        <SignInForm />
      </Col>
    </Row>
  </Container>
);
