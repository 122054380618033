import { useState } from "react";
import "../assets/_hematology.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/Col";
import { MainSquare } from "../features/hemocytometer/square";
import { CellTotals } from "../features/hemocytometer/totals";
import { OptionsForm } from "../features/hemocytometer/options";
import { UserOptionsForm } from "../features/hemocytometer/useroptions";
import { UserAuth } from "../features/auth";

export const Hemocytometer = () => {
  const [cellASquares, setcellASquares] = useState(9);
  const [cellBSquares, setcellBSquares] = useState(9);
  const [countAW, setCountAW] = useState(0);
  const [awKey, setAWKey] = useState("d");
  const [countAR, setCountAR] = useState(0);
  const [arKey, setARKey] = useState("f");
  const [countBW, setCountBW] = useState(0);
  const [bwKey, setBWKey] = useState("j");
  const [countBR, setCountBR] = useState(0);
  const [brKey, setBRKey] = useState("k");
  const [slideThickness, setSlideThickness] = useState(0.1);
  const [dilutionFactor, setDilutionFactor] = useState(1);
  const { user } = UserAuth();

  // useEffect(() => {
  //   console.log("Cell Count is: ", countAW, countAR, countBW, countBR);
  // }, [countAW, countAR, countBW, countBR]);

  return (
    <Container fluid className="majorframe">
      <Container fluid id="optionsframe">
        <Row className="noprint">
          <h2>Options</h2>
          <OptionsForm
            dilutionFactor={dilutionFactor}
            setDilutionFactor={setDilutionFactor}
            slideThickness={slideThickness}
            setSlideThickness={setSlideThickness}
            cellASquares={cellASquares}
            setcellASquares={setcellASquares}
            cellBSquares={cellBSquares}
            setcellBSquares={setcellBSquares}
          />
        </Row>
        {user && (
          <Row>
            <UserOptionsForm user={user} />
          </Row>
        )}
      </Container>
      <Container fluid id="hemesquares">
        <Row>
          <Col>
            <MainSquare
              squarename="A"
              countW={countAW}
              setCountW={setCountAW}
              countR={countAR}
              setCountR={setCountAR}
              wKey={awKey}
              rKey={arKey}
            />
          </Col>
          <Col>
            <MainSquare
              squarename="B"
              countW={countBW}
              setCountW={setCountBW}
              countR={countBR}
              setCountR={setCountBR}
              wKey={bwKey}
              rKey={brKey}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <CellTotals
          cellASquares={cellASquares}
          cellBSquares={cellBSquares}
          slideThickness={slideThickness}
          dilutionFactor={dilutionFactor}
          countAW={countAW}
          countAR={countAR}
          countBW={countBW}
          countBR={countBR}
        />
      </Container>
    </Container>
  );
};
