import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

export const OptionsForm = ({
  dilutionFactor,
  setDilutionFactor,
  slideThickness,
  setSlideThickness,
  cellASquares,
  setcellASquares,
  cellBSquares,
  setcellBSquares,
}) => {
  return (
    <Form className="my-3">
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>Dilution Factor</Form.Label>
          <Form.Control
            className={print}
            type="number"
            value={dilutionFactor}
            onChange={(event) => setDilutionFactor(event.target.value)}
            aria-label="Dilution Factor"
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Thickness</Form.Label>
          <Form.Control
            className={print}
            type="number"
            value={slideThickness}
            onChange={(event) => setSlideThickness(event.target.value)}
            aria-label="Slide Thickness"
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Cell A Squares</Form.Label>
          <Form.Control
            className={print}
            required
            type="number"
            value={cellASquares}
            onChange={(event) => setcellASquares(event.target.value)}
            aria-label="Slide A squares"
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Cell B Squares</Form.Label>
          <Form.Control
            className={print}
            required
            type="number"
            value={cellBSquares}
            onChange={(event) => setcellBSquares(event.target.value)}
            aria-label="Slide B squares"
          />
        </Form.Group>
      </Row>
    </Form>
  );
};
