import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

export const OptionsForm = ({
  countTotal,
  setDisableMax,
  setDiffType,
  skillLevel,
  setskillLevel,
  setSimpleNames,
}) => {
  const [disableMaxOption, setDisableMaxOption] = useState(false);
  const [maxCount, setmaxCount] = useState(100);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (countTotal >= maxCount) {
      setErrorMessage("Diff Count is now " + countTotal);
      if (disableMaxOption) {
        setDisableMax(true);
      }
    }
  }, [countTotal]);

  return (
    <Form className="my-3">
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>Fluid</Form.Label>
          <Form.Select
            defaultValue="Peripheral Blood"
            onChange={(e) => setDiffType(e.target.value)}
          >
            <option>Peripheral Blood</option>
            <option>Cerebrospinal Fluid</option>
            <option>Pleural Fluid</option>
            <option>Peritoneal Fluid</option>
            <option>Synovial Fluid</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Max Count</Form.Label>
          <Form.Control
            type="number"
            value={maxCount}
            onChange={(e) => setmaxCount(parseInt(e.target.value))}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="skilllevel">
          <Form.Label>Skill Level {skillLevel}</Form.Label>
          <Form.Range
            value={skillLevel}
            min="1"
            max="10"
            step="1"
            onChange={(e) => setskillLevel(parseInt(e.target.value))}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Check
            inline
            type="switch"
            id="disable-switch"
            label="Disable Count on Max"
            onChange={(event) => setDisableMaxOption(event.target.checked)}
          />
          <Form.Check
            inline
            type="switch"
            id="simple-switch"
            label="Show Simple Names"
            onChange={(event) => setSimpleNames(event.target.checked)}
          />
        </Form.Group>
      </Row>
    </Form>
  );
};
