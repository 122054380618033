import { useState, useEffect } from "react";
import "../assets/_hematology.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/esm/Row";
import CardGroup from "react-bootstrap/CardGroup";
import Card from "react-bootstrap/esm/Card";
import RCounter from "../components/comprenders.js";
import DiffChart from "../features/diff/chart";
import Col from "react-bootstrap/Col";
import { MainCells } from "../features/diff/cells";
import { MainForm } from "../features/diff/forms/forms";
import { OptionsForm } from "../features/diff/options";
import { UserOptionsForm } from "../features/diff/useroptions";
import { UserAuth } from "../features/auth";

export const ManuelDiff = () => {
  const [countTotal, setCountTotal] = useState(0);
  const [diffData, setDiffData] = useState([]);
  const [diffType, setDiffType] = useState("Peripheral Blood");
  const [simpleNames, setSimpleNames] = useState(false);
  const [disableMax, setDisableMax] = useState(false);
  const [skillLevel, setskillLevel] = useState(5);
  const { user } = UserAuth();
  // RCounter("Main Diff Page");

  useEffect(() => {
    setCountTotal(0);
    if (diffData) {
      diffData.map((DiffData) =>
        setCountTotal((newsetCountTotal) => newsetCountTotal + DiffData.count)
      );
    }
  }, [diffData]);

  return (
    <Container fluid className="majorframe">
      <Row></Row>
      <Container fluid id="optionsframe">
        <Row className="noprint">
          <h2>Options</h2>
          <OptionsForm
            countTotal={countTotal}
            setDisableMax={setDisableMax}
            setDiffType={setDiffType}
            skillLevel={skillLevel}
            setskillLevel={setskillLevel}
            setSimpleNames={setSimpleNames}
          />
        </Row>
        {user && (
          <Row>
            <UserOptionsForm user={user} />
          </Row>
        )}
      </Container>
      <Row className="noprint">
        <h2>Data</h2>
        <CardGroup>
          <Col md={4}>
            <Card
              style={{ width: "15rem" }}
              bg="dark"
              border="light"
              text="light"
            >
              <Card.Header id="header">Total: {countTotal}</Card.Header>
              <Card.Body>
                <DiffChart diffData={diffData} countTotal={countTotal} />
              </Card.Body>
            </Card>
          </Col>
        </CardGroup>
      </Row>

      <Row className="justify-content-center">
        <h2>Cells</h2>
        <CardGroup className="noprint">
          <MainCells
            totalCount={countTotal}
            setDiffData={setDiffData}
            diffData={diffData}
            disableMax={disableMax}
          />
        </CardGroup>
      </Row>
      <MainForm
        setDiffData={setDiffData}
        diffData={diffData}
        skillLevel={skillLevel}
        diffType={diffType}
        simpleNames={simpleNames}
        disableMax={disableMax}
      />
    </Container>
  );
};
