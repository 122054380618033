export const UserName = (user) => {
  if (user) {
    if (user.displayName) {
      return user.displayName;
    } else {
      return user.email;
    }
  } else {
    console.log("returning null");
    return null;
  }
};
