import { Routes, Route } from "react-router-dom";
import { Home } from "../pages/home";
import { NoPage } from "../pages/nopage";
import { BloodBank } from "../pages/bloodbank";
import { HemaTology } from "../pages/hematology";
import { ManuelDiff } from "../pages/diff";
import { Hemocytometer } from "../pages/hemocytometer";
import { Chemistry } from "../pages/chemistry";
import { MicroBiology } from "../pages/microbiology";
import { Legal } from "../pages/legal";
import {
  SignInPage,
  SignOutPage,
  CreateAccountPage,
  ProfilePage,
  ProtectedRoute,
} from "../features/auth";

export const Paths = () => (
  <Routes>
    <Route exact path="" element={<Home />} />
    <Route index element={<Home />} />
    <Route path="bloodbank" element={<BloodBank />} />
    <Route path="hematology" element={<HemaTology />} />
    <Route path="hematology/diff" element={<ManuelDiff />} />
    <Route path="hematology/hemocytometer" element={<Hemocytometer />} />
    <Route path="chemistry" element={<Chemistry />} />
    <Route path="MicroBiology" element={<MicroBiology />} />
    <Route path="login" element={<SignInPage />} />
    <Route path="logout" element={<SignOutPage />} />
    <Route path="create-account" element={<CreateAccountPage />} />
    <Route path="legal" element={<Legal />} />
    <Route
      path="settings"
      element={
        <ProtectedRoute>
          <ProfilePage />
        </ProtectedRoute>
      }
    />
    <Route path="*" element={<NoPage />} />
  </Routes>
);
