import { Chart } from "react-google-charts";
import React, { useState, useEffect } from "react";

const DiffChart = ({ diffData, countTotal }) => {
  const [dataNew, setNewData] = useState([["Cell", "Count"]]);
  const [dataOld, setOldData] = useState([["Cell", "Count"]]);

  const data = {
    old: dataOld,
    new: dataNew,
  };

  const options = {
    backgroundColor: "gray",
    diff: { innerCircle: { radiusFactor: 0.4 } },
  };

  useEffect(() => {
    if (diffData) {
      setNewData([["Cell", "Count"]]);
      setOldData([["Cell", "Count"]]);
      diffData.map((Cell) => {
        setNewData((setNewData) => [...setNewData, [Cell.name, Cell.count]]);
      });

      diffData.map((Cell) => {
        setOldData((setOldData) => [...setOldData, [Cell.name, Cell.refRange]]);
      });
    }
  }, [diffData]);

  return (
    countTotal && (
      <Chart
        width={"100%"}
        height={"100%"}
        chartType="PieChart"
        diffdata={data}
        options={options}
      />
    )
  );
};

export default DiffChart;
