import Container from "react-bootstrap/Container";

import { useHotkeys } from "react-hotkeys-hook";

export const MainCell = ({ count, setCount, hotKey }) => {
  return (
    <td>
      <h2>{count}</h2>
      <NeedHotKeys hotKey={hotKey} setCount={setCount} />
    </td>
  );
};

const NeedHotKeys = ({ setCount, hotKey }) => {
  useHotkeys(`ctrl+${hotKey},${hotKey}`, (_, handler) => {
    switch (handler.key) {
      case `${hotKey}`:
        setCount((setCount) => setCount + 1);
        break;
      case `shift+${hotKey}`:
        setCount((setCount) => setCount - 1);
        break;
    }
  });
  return (
    <Container>
      <h5>Add count with {hotKey}</h5>
      <h5>Substract count with with shift + {hotKey}</h5>
    </Container>
  );
};
