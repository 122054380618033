import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { MainCell } from "./cell";

export const MainSquare = ({
  squarename,
  countW,
  setCountW,
  countR,
  setCountR,
  wKey,
  rKey,
}) => {
  return (
    <Container className="hemesquare">
      <Table striped bordered variant="dark" className="maintable">
        <thead>
          <tr>
            <th colSpan={3}>
              <h2>{squarename}</h2>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <MainCell count={countW} setCount={setCountW} hotKey={wKey} />
            <MainCell count={countR} setCount={setCountR} hotKey={rKey} />
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};
