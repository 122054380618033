import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { NeedHotKeys } from "./hotkeys";
import Col from "react-bootstrap/Col";

export const WhiteCount = ({
  counted,
  diffData,
  setDiffData,
  disableMax,
  print,
  setPrint,
}) => {
  const [cellCount, setCountCell] = useState(0);
  const [cellCountKey, setCountCellKey] = useState(0);
  const [lock, setLock] = useState(false);
  const namecell = useRef(counted.name);
  const keyhot = useRef(counted.hotKey);

  const onChange = ({ target }) => {
    if (!lock) {
      setCountCell(parseInt(target.value));
      setLock(true);
      setCountCellKey(parseInt(target.value));
    }
  };

  useEffect(() => {
    if (!lock) {
      setCountCell(cellCountKey);
      setLock(true);
    }
  }, [cellCountKey]);

  useEffect(() => {
    if (cellCount) {
      const needInit = diffData.some((element) => {
        if (element.name === namecell.current) {
          return true;
        }

        return false;
      });

      setPrint("print");
      if (!needInit) {
        setDiffData((setDiffData) => {
          return [...setDiffData, { name: namecell.current, count: cellCount }];
        });
      }
      if (lock) {
        setDiffData((setDiffData) =>
          setDiffData.map((setDiffData) =>
            setDiffData.name === namecell.current &&
            setDiffData.count != cellCount
              ? { ...setDiffData, count: cellCount }
              : { ...setDiffData }
          )
        );
      }
    }
  }, [cellCount]);

  useEffect(() => {
    diffData.map((diffData) => {
      if (diffData.name === namecell.current && diffData.count != cellCount) {
        setCountCell(diffData.count);
        setCountCellKey(diffData.count);
      }
    });
    setLock(false);
  }, [diffData]);

  return (
    <>
      {counted?.hotKey && !counted?.showtop && (
        <NeedHotKeys
          hotkey={keyhot.current}
          setCountCell={setCountCellKey}
          disableMax={disableMax}
        />
      )}
      <Col className={print}>
        <Form.Control
          className={print}
          type="number"
          value={cellCount}
          onChange={onChange}
          aria-label={namecell.current}
        />
      </Col>
    </>
  );
};
