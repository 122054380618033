import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { UserName } from "../../components/username";

export const UserOptionsForm = ({ user }) => {
  return (
    <Form className="useroptions my-3">
      <Row className="onlyprint mb-3">
        <Form.Group as={Col} xs="auto">
          <Form.Label>UserName</Form.Label>
          <Form.Control
            type="text"
            id="username"
            placeholder={UserName(user)}
            aria-describedby="nameofuser"
            readOnly
          />
          <Form.Text id="iduser"></Form.Text>
        </Form.Group>
      </Row>
      <Row className="print mb-3">
        <Form.Group as={Col}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            id="personname"
            aria-describedby="nameofperson"
          />
          <Form.Text id="idname"></Form.Text>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Number</Form.Label>
          <Form.Control
            type="text"
            id="personnumber"
            aria-describedby="numberofperson"
          />
          <Form.Text id="idnumber"></Form.Text>
        </Form.Group>
      </Row>
    </Form>
  );
};
