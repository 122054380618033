import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

export const CellModal = (props) => (
  <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        {props.cell.name}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>{props.cell?.simplename}</h4>
      <p>{props.cell?.desc && props.cell.desc}</p>
      {props.cell?.sizeL && (
        <Sizes low={props.cell?.sizeL} high={props.cell?.sizeH} />
      )}
      {props.cell?.rangeLow && (
        <ReferenceRange low={props.cell.rangeLow} high={props.cell.rangeHigh} />
      )}
      <Conditions conditions={props.cell?.conditions} />
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide}>Close</Button>
    </Modal.Footer>
  </Modal>
);

const Conditions = ({ conditions }) => {
  if (!conditions) {
    return null;
  }

  return (
    <>
      <h4>Conditions</h4>
      <ListGroup>
        {conditions.map((condition) => (
          <ConditionsList condition={condition} key={condition} />
        ))}
      </ListGroup>
    </>
  );
};

const ConditionsList = ({ condition }) => (
  <ListGroup.Item>{condition}</ListGroup.Item>
);

const Sizes = ({ low, high }) => {
  if (!high || !low) {
    return null;
  }

  return (
    <>
      <h4>
        Sizes {low} μ to {high} μ
      </h4>
    </>
  );
};

const ReferenceRange = ({ low, high }) => (
  <h4>
    Reference Range: {low} % to {high} %
  </h4>
);
