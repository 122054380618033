import hazard from "../assets/icons/biohazard.svg";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Home = () => (
  <Container fluid as="header" className="App-header">
    <Image src={hazard} className="m-5" id="App-logo" alt="logo" />
    <Header />
  </Container>
);

const Header = () => (
  <Container fluid as="header" id="banner">
    <Row className="justify-content-center">
      <Col xs={6} className="banner my-5">
        <Row>
          <h1 className="my-1">Scutulatus</h1>
        </Row>
      </Col>
    </Row>
  </Container>
);
