import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { NeedHotKeys } from "./hotkeys";
import Col from "react-bootstrap/Col";

export const PassCount = ({ counted, print, setPrint, disableMax }) => {
  const [cellCount, setCountCell] = useState(0);
  const namecell = useRef(counted.name);
  const keyhot = useRef(counted.hotkey);

  useEffect(() => {
    if (cellCount && cellCount > 0) {
      setPrint("print");
    } else {
      setPrint("noprint");
    }
  }, [cellCount, setPrint]);

  return (
    <>
      {counted?.hotKey && !counted?.showtop && (
        <NeedHotKeys
          hotkey={counted.hotKey}
          setCountCell={setCountCell}
          disableMax={disableMax}
        />
      )}
      <Col className={print}>
        <Form.Control
          className={print}
          type="number"
          value={cellCount}
          onChange={(event) => setCountCell(event.target.value)}
          aria-label={counted.name}
        />
      </Col>
    </>
  );
};
