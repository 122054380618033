import { useState, useEffect } from "react";
import { CountButton } from "./countbutton";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import { CellModal } from "./cellmodal";

export const MainColumn = ({
  counted,
  diffData,
  setDiffData,
  disableMax,
  simpleNames,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [ColumnSize, setColumnSize] = useState(4);
  const [print, setPrint] = useState("noprint");
  useEffect(() => {
    if (simpleNames) {
      setColumnSize(6);
    } else {
      setColumnSize(4);
    }
  }, [simpleNames]);
  return (
    <InputGroup className="noprint">
      <Col sm={ColumnSize} className={print}>
        <InputGroup.Text onClick={() => setModalShow(true)}>
          {counted.name}
          {simpleNames && counted.simplename ? ` (${counted?.simplename})` : ""}
        </InputGroup.Text>
      </Col>
      <CountButton
        counted={counted}
        diffData={diffData}
        setDiffData={setDiffData}
        disableMax={disableMax}
        print={print}
        setPrint={setPrint}
      />
      <CellModal
        cell={counted}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </InputGroup>
  );
};
