import station from "../assets/icons/space-station.svg";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

export const NoPage = () => (
  <Container>
    <Row className="justify-content-md-center">
      <h1>404</h1> <h2>This is not the page you are looking for</h2>
    </Row>
    <Row className="justify-content-md-center">
      <Col md={4}>
        <Image src={station} alt="station" />
      </Col>
    </Row>
    <Row className="justify-content-md-center">
      <h4>Move Along</h4> <h4>Move Along</h4>
    </Row>
  </Container>
);
