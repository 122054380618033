import { useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useLocation, NavLink, Outlet } from "react-router-dom";
import { UserAuth } from "../features/auth";
import { UserName } from "../components/username";
import { getAnalytics, logEvent } from "firebase/analytics";

export const MainNav = () => {
  const location = useLocation();
  const analytics = getAnalytics();
  useEffect(() => {
    logEvent(analytics, location);
  }, [location, analytics]);
  return (
    <Container fluid className="pb-5">
      <Row>
        <Navbar
          collapseOnSelect="true"
          expand="sm"
          bg="dark"
          variant="dark"
          fixed="top"
        >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <MainTabs />
            <Outlet />
          </Navbar.Collapse>
          <UserTabs />
        </Navbar>
      </Row>
    </Container>
  );
};

function MainTabs() {
  return (
    <Nav className="justify-content-start" defaultActiveKey="#home">
      <Nav.Item as="li" className="me-auto">
        <Nav.Link as={NavLink} eventKey="home" to="/">
          Home
        </Nav.Link>
      </Nav.Item>
      <HemDrop />
      <Nav.Item as="li" className="me-auto">
        <Nav.Link eventKey="disabled" disabled as={NavLink} to="/bloodbank">
          Blood Bank
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as="li" className="me-auto"></Nav.Item>
      <Nav.Item as="li" className="me-auto">
        <Nav.Link eventKey="disabled" disabled as={NavLink} to="/chemistry">
          Chemistry
        </Nav.Link>
      </Nav.Item>
      <Nav.Item as="li" className="me-auto">
        <Nav.Link eventKey="disabled" disabled as={NavLink} to="/microbiology">
          MicroBiology
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

const UserTabs = () => {
  const { user } = UserAuth();
  if (user) {
    return (
      <Nav className="justify-content-end" variant="pills">
        <Nav.Item className="px-5">
          <Nav.Link as={NavLink} eventKey="settings" to="/settings">
            {UserName(user)}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="signout" as={NavLink} to="/logout">
            Sign Out
          </Nav.Link>
        </Nav.Item>
      </Nav>
    );
  } else {
    return (
      <Nav className="justify-content-end">
        <Nav.Item>
          <Nav.Link as={NavLink} eventKey="login" to="/login">
            Sign in
          </Nav.Link>
        </Nav.Item>
      </Nav>
    );
  }
};

const HemDrop = () => (
  <NavDropdown title="Hematology" id="hem-nav-dropdown">
    <NavDropdown.Item as={NavLink} eventKey={1} to="/hematology">
      Main
    </NavDropdown.Item>
    <NavDropdown.Item as={NavLink} eventKey={2} to="/hematology/diff">
      Diff
    </NavDropdown.Item>
    <NavDropdown.Item as={NavLink} eventKey={3} to="/hematology/Hemocytometer">
      Hemocytometer
    </NavDropdown.Item>
  </NavDropdown>
);
