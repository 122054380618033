import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Header = ({ title }) => (
  <Container fluid id="banner">
    <Row className="justify-content-center">
      <Col xs={6} className="banner my-5">
        <Row>
          <h1 className="my-1">{title}</h1>
        </Row>
      </Col>
    </Row>
  </Container>
);
