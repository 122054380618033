import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Config from "../../../data/diff.json";

import { MainColumn } from "./maincolumn";

export const MainForm = ({
  setDiffData,
  diffData,
  skillLevel,
  diffType,
  simpleNames,
  disableMax,
}) => {
  const diff = typeDiff(diffType);
  const whiteCells = diff.whiteCells.map((whiteCell) => {
    return (
      <ColumnPass
        counted={whiteCell}
        setDiffData={setDiffData}
        diffData={diffData}
        key={whiteCell.id}
        skillLevel={skillLevel}
        disableMax={disableMax}
        simpleNames={simpleNames}
      />
    );
  });
  const redCells = diff.redCells.map((redCell) => {
    return (
      <ColumnPass
        counted={redCell}
        key={redCell.id}
        skillLevel={skillLevel}
        disableMax={disableMax}
        simpleNames={simpleNames}
      />
    );
  });
  const otherCells = diff.otherCells.map((otherCell) => {
    return (
      <ColumnPass
        counted={otherCell}
        key={otherCell.id}
        skillLevel={skillLevel}
        disableMax={disableMax}
        simpleNames={simpleNames}
      />
    );
  });
  return (
    <Container fluid id="formcounts">
      <Row>
        <Col md={4}>
          <Form className="py-3">
            <Form.Label>
              <h3 className="print">White Cells</h3>
            </Form.Label>
            {whiteCells}
          </Form>
        </Col>
        <Col md={4}>
          <Form className="py-3">
            <Form.Label>
              <h3 className="print">Red Cells</h3>
            </Form.Label>
            {redCells}
          </Form>
        </Col>
        <Col md={4}>
          <Form className="py-3">
            <Form.Label>
              <h3 className="print">Other Cells</h3>
            </Form.Label>{" "}
            {otherCells}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const ColumnPass = ({
  counted,
  diffData,
  setDiffData,
  skillLevel,
  disableMax,
  simpleNames,
}) => {
  if (skillLevel && counted.levelL && counted.levelH) {
    if (skillLevel < counted.levelL || skillLevel > counted.levelH) return null;
  }

  return (
    <MainColumn
      counted={counted}
      setDiffData={setDiffData}
      diffData={diffData}
      disableMax={disableMax}
      simpleNames={simpleNames}
    />
  );
};

const typeDiff = (diffType) => {
  switch (diffType) {
    case "Cerebrospinal Fluid":
      return Config.Cerebrospinal;
    case "Pleural Fluid":
      return Config.Pleural;
    case "Peritoneal Fluid":
      return Config.Peritoneal;
    case "Synovial Fluid":
      return Config.Synovial;
    default:
      return Config.Peripheral;
  }
};
