import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { useMemo } from "react";
// import Rcounter from "../../components/comprenders.js";

export const CellTotals = ({
  cellASquares,
  cellBSquares,
  slideThickness,
  dilutionFactor,
  countAW,
  countAR,
  countBW,
  countBR,
}) => {
  const averageAW = useMemo(
    () => AverageCells(countAW, cellASquares, slideThickness, dilutionFactor),
    [countAW, cellASquares, slideThickness, dilutionFactor]
  );
  const averageAR = useMemo(
    () => AverageCells(countAR, cellASquares, slideThickness, dilutionFactor),
    [countAR, cellASquares, slideThickness, dilutionFactor]
  );
  const averageBW = useMemo(
    () => AverageCells(countBW, cellBSquares, slideThickness, dilutionFactor),
    [countBW, cellBSquares, slideThickness, dilutionFactor]
  );
  const averageBR = useMemo(
    () => AverageCells(countBR, cellBSquares, slideThickness, dilutionFactor),
    [countBR, cellBSquares, slideThickness, dilutionFactor]
  );

  // console.log(Rcounter("CellTotals"));

  // useEffect(() => {
  //   console.log(
  //     "Cell Count in totals is: ",
  //     countAW,
  //     countAR,
  //     countBW,
  //     countBR
  //   );
  // }, [countAW, countAR, countBW, countBR]);

  return (
    <Container fluid>
      <Table>
        <thead>
          <tr>
            <th>Side A Average White Count</th>
            <th>Side A Average Red Count</th>
            <th>Side B Average White Count</th>
            <th>Side B Average Red Count</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {averageAW}
            {averageAR}
            {averageBW}
            {averageBR}
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

const AverageCells = (count, squares, thickness, dilutionFactor) => {
  const averageCells = Math.round((count / squares) * dilutionFactor);
  return (
    <td>
      <h2>{averageCells}</h2>
    </td>
  );
};
