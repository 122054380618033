import { Link } from "react-router-dom";

export const ButtonMailto = ({ label }) => {
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = contactEmail;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};
