import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ButtonMailto } from "../components/contact";

export const Footer = () => {
  const [currentYear, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const Year = new Date().getFullYear();
    setYear(Year);
  }, [currentYear]);

  return (
    <Container fluid as="footer" className="mt-auto">
      <Row className="justify-content-md-center">
        <Col md="auto">
          <ButtonMailto label="Contact" />
        </Col>
        <Col className="copyright">
          &copy; Copyright {currentYear && currentYear}
        </Col>
        <Col md="auto">
          <Link to="/legal">Legal Disclaimer</Link>
        </Col>
      </Row>
    </Container>
  );
};
