import { useState, useEffect, useRef, useMemo } from "react";
import Card from "react-bootstrap/esm/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import Config from "../../data/diff.json";
import { useHotkeys } from "react-hotkeys-hook";
import RCounter from "../../components/comprenders.js";

const CellsID = ({
  cellName,
  hotkey,
  totalCount,
  setDiffData,
  diffData,
  refRange,
  disableMax,
}) => {
  const [cellCount, setCountCell] = useState(0);
  const [lock, setLock] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const namecell = useRef(cellName);
  const countPercent = useMemo(() => {
    if (cellCount > 0) {
      return Math.round((cellCount / totalCount) * 100);
    }
    return 0;
  }, [totalCount, cellCount]);

  useEffect(() => {
    if (disableMax) {
      setEnabled(false);
    }
  }, [disableMax]);

  useHotkeys(
    `ctrl+${hotkey},${hotkey}`,
    (_, handler) => {
      switch (handler.key) {
        case `${hotkey}`:
          if (!lock) {
            setCountCell((setCountCell) => setCountCell + 1);
            setLock(true);
          }
          break;
        case `ctrl+${hotkey}`:
          if (!lock) {
            setCountCell((setCountCell) => setCountCell - 1);
            setLock(true);
          }
          break;
      }
    },
    { enabled }
  );

  useEffect(() => {
    if (cellCount && lock) {
      const needInit = diffData.some((element) => {
        if (element.name === namecell.current) {
          return true;
        }

        return false;
      });

      if (!needInit) {
        setDiffData((setDiffData) => {
          return [
            ...setDiffData,
            { name: namecell.current, count: cellCount, refRange: refRange },
          ];
        });
      }
      setDiffData((setDiffData) =>
        setDiffData.map((setDiffData) =>
          setDiffData.name === namecell.current &&
          setDiffData.count != cellCount
            ? { ...setDiffData, count: cellCount }
            : { ...setDiffData }
        )
      );
    }
  }, [cellCount]);

  useEffect(() => {
    diffData.map((diffData) => {
      if (diffData.name === namecell.current && diffData.count != cellCount) {
        setCountCell(diffData.count);
        setLock(false);
      }
    });
  }, [diffData]);

  return (
    <Card style={{ width: "15rem" }} id="infoblock">
      <Card.Header id="header">{namecell.current}</Card.Header>
      <Card.Body>
        <ListGroup>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Cell Count</div>
            </div>
            <Badge bg="dark">{cellCount && cellCount}</Badge>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
            disabled
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Cell Percent</div>
            </div>
            <Badge bg="dark">{totalCount && countPercent} %</Badge>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
      <Card.Footer id="footer">Keys: {hotkey}</Card.Footer>
    </Card>
  );
};

export const MainCells = ({
  totalCount,
  setDiffData,
  diffData,
  disableMax,
}) => {
  const CellCards = Config.Peripheral.whiteCells.map((cell, index) => {
    if (cell.showtop) {
      return (
        <CellsID
          cellName={cell.name}
          hotkey={cell.hotKey}
          setDiffData={setDiffData}
          diffData={diffData}
          totalCount={totalCount}
          refRange={(cell.rangeHigh + cell.rangeLow) / 2}
          disableMax={disableMax}
          key={index}
        />
      );
    }
  });
  return <>{CellCards}</>;
};
