import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "./context";

export const SignOutForm = () => {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      <h2>Account</h2>
      <h3>{user.displayName && user.displayName}</h3>
      <p>User Email: {user && user.email}</p>

      <Button onClick={handleLogout} variant="dark">
        Logout
      </Button>
    </>
  );
};
