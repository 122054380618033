import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/esm/Row";
import Button from "react-bootstrap/Button";
import validator from "validator";
import { UserAuth } from "./context";
import { useNavigate } from "react-router-dom";

export const CreateAccountPage = () => {
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // const [bio, setBio] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const navigate = useNavigate();
  const { createUser } = UserAuth();

  const validateForm = () => {
    if (!emailValue) return "Please fill out all fields";
    if (!validator.isEmail(emailValue))
      return "Please enter a valid email address";
    if (passwordValue !== confirmPassword) return "Passwords do not match";
    return null;
  };

  const onSignUpClicked = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setErrorCode("");
    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    try {
      await createUser(emailValue, passwordValue);
      navigate("/");
    } catch (e) {
      setErrorCode(e.code);
      setErrorMessage(e.message);
      console.log(errorCode, errorMessage);
    }
    setLoading(false);
  };

  return (
    <Container fluid="sm" className="m-3 p-3">
      <Row className="justify-content-center">
        <Col md={3} id="majorframe">
          <Form onSubmit={onSignUpClicked}>
            <h2>Create an Account</h2>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form.Group>
              {/* <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="John"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Doe"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              /> */}
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                value={emailValue}
                placeholder="john.doe@gmail.com"
                onChange={(e) => setEmailValue(e.target.value)}
              />
              <Form.Label>Set Password</Form.Label>
              <Form.Control
                required
                type="password"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
              />
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                required
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {/* <Form.Label>Bio</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={bio}
                placeholder="Your Bio"
                style={{ width: "100%" }}
                onChange={(e) => setBio(e.target.value)}
              /> */}
            </Form.Group>
            <Form.Group className="m-2">
              <Button type="submit" disabled={loading} variant="dark">
                Create Account
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
