import React, { useState, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import { useHotkeys } from "react-hotkeys-hook";
import Col from "react-bootstrap/Col";

export const NeedHotKeys = ({ hotkey, setCountCell, disableMax }) => {
  const [enabled, setEnabled] = useState(true);
  useEffect(() => {
    if (disableMax) {
      setEnabled(false);
    }
  }, [disableMax]);

  useHotkeys(
    `ctrl+${hotkey},${hotkey}`,
    (_, handler) => {
      switch (handler.key) {
        case `${hotkey}`:
          setCountCell((setCountCell) => setCountCell + 1);
          break;
        case `ctrl+${hotkey}`:
          setCountCell((setCountCell) => setCountCell - 1);
          break;
      }
    },
    { enabled }
  );
  return (
    <Col xs={1}>
      <InputGroup.Text className="formhotkeys">{hotkey}</InputGroup.Text>
    </Col>
  );
};
