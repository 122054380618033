import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export const OptionPresent = ({ print, setPrint }) => {
  const [grade, setGrade] = useState(0);

  useEffect(() => {
    if (grade && grade != 0) {
      setPrint("print");
    } else {
      setPrint("noprint");
    }
  }, [grade, setPrint]);

  return (
    <Col className={print}>
      <Form.Select
        aria-label="Cells"
        onChange={(event) => setGrade(event.target.value)}
        className={print}
      >
        <option value="0"></option>
        <option value="1">Present</option>
        <option value="2">Not Present</option>
      </Form.Select>
    </Col>
  );
};
