import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/esm/Row";

const Libel =
  "THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS “AS IS” AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.";

const useofSoftware =
  "Copying or reproducing the Software to any other server or location for further reproduction or redistribution is strictly prohibited.  You may not create derivative works of the Software, or attempt to decompile or reverse-engineer the Software unless otherwise permitted by law.  You may not use the Software for any illegal purpose, and you may not use the Software to violate the privacy or infringe the rights of others.  You may not use the Software to send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortuous material, or to send or store material in violation of third party privacy rights.  You may not use the Software to send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs.  You may not use the Software to interfere with or disrupt the integrity or performance of the Software or third party data contained therein.  You may not use the Software to attempt to gain unauthorized access to the Software or its related systems or networks.";

export const Legal = () => {
  return (
    <Container fluid className="legal">
      <Row className="justify-content-center">
        <Col md={3} id="majorframe">
          <h1>Legal</h1>
          <p>{Libel}</p>
          <p>{useofSoftware}</p>
        </Col>
      </Row>
    </Container>
  );
};
