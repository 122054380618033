import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

export const OptionCount = ({ print, setPrint }) => {
  const [grade, setGrade] = useState(0);

  useEffect(() => {
    if (grade && grade != 0) {
      setPrint("print");
    } else {
      setPrint("noprint");
    }
  }, [grade, setPrint]);

  return (
    <Col className={print}>
      <Form.Select
        aria-label="Cell Grading"
        onChange={(event) => setGrade(event.target.value)}
        className={print}
      >
        <option value="0"></option>
        <option value="1">1+</option>
        <option value="2">2+</option>
        <option value="3">3+</option>
        <option value="4">4+</option>
      </Form.Select>
    </Col>
  );
};
