import React, { useState, useEffect } from "react";
import validator from "validator";
import { UserAuth } from "./context";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { getAuth, updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const Settings = () => {
  const [displayName, setdisplayName] = useState("John");
  const [photoURL, setphotoURL] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user.displayName) {
        setdisplayName(user.displayName);
      }
      if (user.photoURL) {
        setphotoURL(user.photoURL);
      }
      setLoading(false);
    }
  }, []);

  const validateForm = () => {
    if (!displayName) return "Please fill out Display Name";
    if (photoURL && !validator.isURL(photoURL))
      return "Please enter a valid url";
  };

  const onChangeClicked = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setErrorCode("");
    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }
    try {
      await updateProfile(auth.currentUser, {
        displayName: displayName,
        photoURL: photoURL,
      });
      navigate("/");
    } catch (e) {
      setErrorCode(e.code);
      setErrorMessage(e.message);
      console.log(errorCode, errorMessage);
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={onChangeClicked}>
      <h2>Edit Profile</h2>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Form.Group className="mb-3">
        <Form.Label>Display Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="John"
          value={displayName}
          onChange={(e) => setdisplayName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Photo Url</Form.Label>
        <Form.Control
          type="url"
          placeholder="http://default.com"
          value={photoURL}
          onChange={(e) => setphotoURL(e.target.value)}
        />
      </Form.Group>
      <Button disabled={loading} type="submit" variant="dark">
        Update Profile
      </Button>
    </Form>
  );
};
