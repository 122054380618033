import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "./context";

export const SignInForm = () => {
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const onSignInClicked = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setErrorCode("");
    try {
      await signIn(emailValue, passwordValue);
      navigate("/");
    } catch (e) {
      setErrorCode(e.code);
      setErrorMessage(e.message);
      console.log(errorCode, errorMessage);
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={onSignInClicked}>
      <h2>Sign in to the Laboratory</h2>
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Enter email"
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={passwordValue}
          onChange={(e) => setPasswordValue(e.target.value)}
        />
      </Form.Group>
      <Button disabled={loading} type="submit" variant="dark">
        Submit form
      </Button>
      <Form.Group className="m-2">
        <p>Don&apos;t have an account yet?</p>
        <Link to="/create-account">Sign Up</Link>
      </Form.Group>
    </Form>
  );
};
